export default [
  // {
  //   id: 'a',
  //   display: 'A',
  //   displaySub: '',
  //   type: 'STUDIO',
  //   sqft: '331 - 338',
  //   levels: ['2', '3', '4'],
  // },
  // {
  //   id: 'b',
  //   display: 'B',
  //   displaySub: '',
  //   type: '1 BED + 1 BATH',
  //   sqft: '505 - 606',
  //   levels: ['Ground', '1', '3'],
  // },
  // {
  //   id: 'b7',
  //   display: 'B7',
  //   displaySub: '',
  //   type: '1 BED + 1 BATH',
  //   sqft: '500 - 521',
  //   levels: ['2', '3'],
  // },

  // {
  //   id: 'c',
  //   display: 'C',
  //   displaySub: '',
  //   type: '1 BED + DEN + 1 BATH',
  //   sqft: '618',
  //   // levels: ['3', '4'], // original
  //   levels: ['4'],
  // },
  // {
  //   id: 'd',
  //   display: 'D',
  //   displaySub: '',
  //   type: "2 BED + 1 BATH",
  //   sqft: "636",
  //   levels: ['1', '2', '3'],
  // },

  {
    id: 'f',
    display: 'F',
    displaySub: '',
    type: '2 BED + 1 BATH',
    sqft: '629 - 669',
    levels: ['2', '3', '4'],
  },
  {
    id: 'g',
    display: 'G',
    displaySub: '',
    type: '2 BED + 1 BATH',
    sqft: '727 - 933',
    levels: ['2', '3', '4'],
  },
  {
    id: 'h',
    display: 'H',
    displaySub: '',
    type: '2 BED + 1 BATH',
    sqft: '733',
    levels: ['3', '4'],
  },
  {
    id: 'i',
    display: 'I',
    displaySub: '',
    type: '2 BED + 1 BATH',
    sqft: '826',
    levels: ['4'],
  },
  {
    id: 'k',
    display: 'K',
    displaySub: '',
    type: '2 BED + 2 BATH',
    sqft: '676',
    levels: ['4'],
  },
  {
    id: 'l',
    display: 'L',
    displaySub: '',
    type: '2 BED + 2 BATH',
    sqft: '861 - 865',
    levels: ['4'],
  },
  {
    id: 'n',
    display: 'N',
    displaySub: '',
    type: '2 BED + 2 BATH',
    sqft: '813',
    levels: ['4'],
  },
  {
    id: 'o',
    display: 'O',
    displaySub: '',
    type: '2 BED + 2 BATH',
    sqft: '1,201',
    levels: ['4'],
  },
  {
    id: 'p',
    display: 'P',
    displaySub: '',
    type: '3 BED + 2 BATH',
    sqft: '862 - 871',
    // levels: ['Ground', '1', '2', '3'], // original
    levels: ['1', '2', '3'],
  },

  {
    id: 'q',
    display: 'Q',
    displaySub: '',
    type: '3 BED + 2 BATH',
    sqft: '874 - 884',
    // levels: ['Ground', '1', '2', '3'], // original
    levels: ['1', '2', '3'],
  },
  {
    id: 'r',
    display: 'R',
    displaySub: '',
    type: '3 BED + 2 BATH',
    sqft: '2,007',
    levels: ['4'],
  },
];
