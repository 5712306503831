import React, { useState } from 'react';
import styled from 'styled-components';

import useIntl from 'components/useIntl';
import Title from 'components/Layout/Title';
import Table from './elements/Table';

const Floorplans = () => {
  const [activePlan, setActivePlan] = useState(null);

  const { t } = useIntl();

  const handlePlan = (i) => {
    if (activePlan === i) {
      setActivePlan(null);
    } else {
      setActivePlan(i);
    }
  };

  return (
    <Root>
      <Title text={t.floorplans.title} small gradient />
      <Table activePlan={activePlan} handlePlan={handlePlan} />
    </Root>
  );
};

export default Floorplans;

const Root = styled.div`
  background-color: #fcfbf8;
`;
