import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Title from 'components/Layout/Title';
import Form from './elements/Form';
import media from 'src/styles/media';
import vw from 'src/styles/utils';
import RegisterInfo from './RegisterInfo';

const Register = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Title text={t.register} gradient />
      <Wrapper>
        <Form />
        <RegisterInfo />
      </Wrapper>
    </Root>
  );
};

export default Register;

const Root = styled.div`
  ${vw('padding-bottom', 80, 120, 160)}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  flex-direction: column;
  @media ${media.tablet} {
    flex-direction: column;
  }
  @media ${media.desktop} {
    flex-direction: row;
  }
  ${vw('margin-top', 28, 40, 80)}
  ${vw('padding-right', 16, 120, 200)}
  ${vw('padding-left', 16, 120, 200)}
`;
