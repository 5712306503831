import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import media from 'src/styles/media';
import vw from 'src/styles/utils';

const RegisterInfo = () => {
  return (
    <Root>
      <Button
        text="download application form"
        lightPink
        className="download"
        href="/pdfs/Application-for-Tenancy-Fillable.pdf"
      />
      <InfoWrapper>
        <Info>
          <h3 className="small">Contact us</h3>
          <p>
            <a href="tel: +12363085989">236.308.5989</a>
          </p>
          <p>
            <a href="mailto: admin@rentatsydney.com">admin@rentatsydney.com</a>
          </p>
        </Info>
        <Info>
          <h3 className="small">Visit us</h3>
          <p>
            545 Sydney Ave,
            <br />
            Coquitlam, BC, V3J 3X5
          </p>
        </Info>
        <Info>
          <h3 className="small">Hours of operation</h3>
          {/* <p>
            <span>Monday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Tuesday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Wednesday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Thursday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Friday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Saturday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Sunday</span>
            <span>9 AM - 6 PM</span>
          </p> */}
          <p>By appointment only.</p>
        </Info>
      </InfoWrapper>
    </Root>
  );
};

export default RegisterInfo;

const Root = styled.div`
  ${vw('margin-top', 17, 40, 50)}
  width: 100%;
  @media ${media.tablet} {
    ${vw('margin-top', 17, 40, 50)}
    width: 100%;
  }
  @media ${media.desktop} {
    width: initial;
  }
  .download {
    width: 100%;
  }
  .button_label {
    width: 100%;
  }
`;

const InfoWrapper = styled.div`
  ${vw('padding-top', 17, 40, 50)}
  ${vw('padding-bottom', 34, 60, 90)}

  ${vw('padding-left', 17, 60, 80)}
  ${vw('padding-right', 17, 60, 80)}
  border: 1px solid ${({ theme }) => theme.color.navy};
  ${vw('margin-top', 10, 20, 66)};
  .download {
    width: 100% !important;
  }
`;

const Info = styled.div`
  ${vw('margin-top', 20, 20, 40)}
  h3 {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    ${vw('font-size', 12, 14, 14)}
    ${vw('line-height', 16, 21, 21)}
    ${vw('letter-spacing', 2, 2, 2)}
    letter-spacing: 2px;
    text-transform: uppercase;
    ${vw('margin-bottom', 8, 8, 8)}
  }
  p {
    display: flex;
    justify-content: space-between;
    font-family: 'Kessel-105';
    font-style: normal;
    font-weight: 400;
    ${vw('font-size', 12, 14, 14)}
    ${vw('line-height', 16, 24, 24)}
    ${vw('letter-spacing', 0.5, 0.5, 0.5)}
    color: #333F48;
    a {
      font-family: 'Kessel-105';
      font-style: normal;
      font-weight: 400;
      ${vw('font-size', 12, 14, 14)}
      ${vw('line-height', 16, 21, 21)}
      ${vw('letter-spacing', 0.5, 0.5, 0.5)}
      color: #333F48;
    }
  }
`;
