import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';
import theme from 'src/styles/theme';
import Hero from 'components/Layout/Hero';
import ContentBlock from 'components/Layout/ContentBlock';
import LineCallout from '../../components/Layout/LineCallout';
import vw from 'src/styles/utils';

const Amenities = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero
        title={t.amenities.hero.title}
        gradient
        // src={require('src/assets/images/amenities/hero.jpg')}
        // fullStrapline={t.amenities.hero.fullStrapline}
      />
      <ContentBlock
        title={t.amenities.content1.title}
        text={t.amenities.content1.body}
        reverse
        gallery={[
          {
            src: require('src/assets/amenities/lobby.jpg'),
            alt: 'Lobby',
          },
          {
            src: require('src/assets/amenities/slider_playground.jpg'),
            alt: 'Terrace',
          },
        ]}
      />
      <ContentBlock
        title={t.amenities.content2.title}
        text={t.amenities.content2.body}
        image={require('src/assets/amenities/slider_gym.jpg')}
      />
      <LineCallout text={t.amenities.callout} lineColor={theme.color.copper} />
    </Root>
  );
};

export default Amenities;

const Root = styled.div`
  ${vw('margin-bottom', 80, 80, 120)}
`;
