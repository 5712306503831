import useIntl from 'components/useIntl';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import Button from 'components/Button';

import media from 'src/styles/media';
import vw from 'src/styles/utils';

const Nav = (props) => {
  const { t, getLocaleURL } = useIntl();
  const { isOpen, setIsOpen, hasScrolledPast, isScrollingDown, isHome } = props;

  const location = useLocation();

  const hasLoaded = useSelector((state) => state.load.hasLoaded);

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    console.log(hasLoaded);
  }, [hasLoaded])


  return (
    <Root
      hasScrolledPast={hasScrolledPast}
      isScrollingDown={isScrollingDown}
      hasLoaded={hasLoaded}
      isHome={isHome}
    >
      <Logo
        to={`${getLocaleURL()}`}
        isHome={isHome}
        hasScrolledPast={hasScrolledPast}
      >
        <img
          src={require('src/assets/images/_global/logos/sydney_logo.png')}
          alt="Sydney Logo"
        />
      </Logo>
      <Hamburger isOpen={isOpen} onClick={handleMenu}>
        <span />
        <span />
        <span />
      </Hamburger>

      <Container>
        <Links>
          {t['header'].routes.map((route, i) => (
            <StyledLink
              active={location.pathname.includes(route.path)}
              key={i}
              to={`${getLocaleURL()}${route.path}`}
              className="main_menu"
            >
              {route.text}
            </StyledLink>
          ))}
        </Links>
        <StyledButton
          to={`${getLocaleURL()}/register`}
          text={t['register']}
          lightPink
        />
      </Container>
    </Root>
  );
};

export default Nav;

Nav.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,

  hasScrolledPast: PropTypes.bool,
  isScrollDown: PropTypes.bool,
  isHome: PropTypes.bool,
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInCss = css`
  animation: ${fadeIn} 0.4s ease forwards 2.5s;
`;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  transition: ${({ theme }) => theme.transition};
  opacity: ${(props) => (props.isHome ? 0 : 1)};

  ${(props) => props.isHome && props.hasLoaded && fadeInCss}
`;

const Logo = styled(Link)`
  img {
    display: block;
    transition: ${({ theme }) => theme.transition};
    opacity: ${(props) => (props.isHome && !props.hasScrolledPast ? 0 : 1)};
    ${vw('width', 48, 75, 75)}
  }
`;

const Hamburger = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${vw('width', 20)}
  ${vw('height', 14)}
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: white;
    position: absolute;
    transition: all 0.4s ease;

    &:nth-of-type(1) {
      top: ${(props) => (props.isOpen ? '50%' : '0')};
      left: 0;
      transform: ${(props) =>
    props.isOpen ? 'translateY(-50%) rotate(45deg)' : ''};
    }

    &:nth-of-type(2) {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      opacity: ${(props) => (props.isOpen ? '0' : '1')};
    }

    &:nth-of-type(3) {
      top: ${(props) => (props.isOpen ? '50%' : 'calc(100% - 2px)')};
      left: 0;
      transform: ${(props) =>
    props.isOpen ? 'translateY(-50%) rotate(-45deg)' : ''};
    }
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
`;

const Links = styled.div`
  display: none;
  @media ${media.desktop} {
    display: flex;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => (props.active ? props.theme.color.lightPink : 'white')};
  text-decoration: none;
  ${vw('margin-right', 66)}
  @media ${media.desktop} {
    &:hover {
      color: ${({ theme }) => theme.color.lightPink};
    }
  }
`;
