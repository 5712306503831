import React, { useState } from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import floorplanData from 'src/data/floorplans';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Table = (props) => {
  const { activePlan, handlePlan } = props;
  const { t } = useIntl();

  const [data, setData] = React.useState(floorplanData);

  const [cFilter, setCFilter] = useState('ALL');

  React.useEffect(() => {
    if (cFilter === 'ALL') {
      setData(floorplanData);
      return;
    }
    setData(floorplanData.filter((fp) => fp.type.includes(cFilter)));
  }, [cFilter]);

  return (
    <Root>
      <Filter>
        <p className="small">filter by:</p>
        <div className="buttonWrapper">
          <button
            className={cFilter === 'ALL' ? 'active' : null}
            onClick={() => setCFilter('ALL')}
          >
            all
          </button>
          {/* re-enable studio button if client wants by uncommenting below */}
          {/* <button
            className={cFilter === 'STUDIO' ? 'active' : null}
            onClick={() => setCFilter('STUDIO')}
          >
            studio
          </button> */}
          {/* <button
            className={cFilter === '1 BED' ? 'active' : null}
            onClick={() => setCFilter('1 BED')}
          >
            one bed
          </button> */}
          <button
            className={cFilter === '2 BED' ? 'active' : null}
            onClick={() => setCFilter('2 BED')}
          >
            two bed
          </button>
          <button
            className={cFilter === '3 BED' ? 'active' : null}
            onClick={() => setCFilter('3 BED')}
          >
            three bed
          </button>
        </div>
      </Filter>

      <Header>
        <p className="col1">{t.floorplans.plan}</p>
        <p className="col2">{t.floorplans.type}</p>
        <p className="col3">{t.floorplans.size}</p>
        <p className="col4">{t.floorplans.level}</p>
      </Header>

      <Plans>
        {data.map((plan, i) => (
          <Plan active={activePlan === i} key={i}>
            <Card active={activePlan === i} onClick={() => handlePlan(i)}>
              <div className="col1 name">
                <p>
                  {plan.display}
                  <span>{plan.displaySub}</span>
                </p>
              </div>

              <div className="details">
                <div className="col2">
                  <p>{plan.type}</p>
                </div>

                <div className="col3">
                  <p>
                    {plan.sqft}{' '}
                    <span className="mobile">{t.floorplans.size}</span>
                  </p>
                </div>
                <div className="col4">
                  <p>
                    {plan.levels.join(', ')}{' '}
                    <span className="mobile">{t.floorplans.level}</span>
                  </p>
                </div>
                <div className="col5">
                  <button>view plan</button>
                </div>
                {/* <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 1.65625L9 9.65625L0.999999 1.65625"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg> */}
              </div>
            </Card>

            <Container>
              <img
                src={require(`src/assets/floorplans/plans/${plan.id}.png`)}
                alt=""
                className="png"
              />

              <div>
                <img
                  src={require(`src/assets/floorplans/thumbs/${plan.id}.svg`)}
                  alt=""
                  className="thumb"
                />

                <Button
                  text={t.floorplans.download}
                  href={`/pdfs/floorplans/${plan.id}.pdf`}
                  lightPink
                />
              </div>
            </Container>
          </Plan>
        ))}
      </Plans>

      <Disclaimer className="small">{t.floorplans.disclaimer}</Disclaimer>
    </Root>
  );
};

export default Table;

Table.propTypes = {
  activePlan: PropTypes.number,
  handlePlan: PropTypes.func,
};

const Root = styled.div`
  margin: 0 auto;
  ${vw('padding-right', 16, 60, 200)}
  ${vw('padding-left', 16, 60, 200)}
  ${vw('margin-top', 60, 188, 120)}
  ${vw('padding-bottom', 80, 120, 160)}

  .header {
  }
  .col1 {
    ${vw('width', 72, 107, 214)}
  }
  .col2 {
    ${vw('width', 'auto', 317, 403)}
  }
  .col3 {
    ${vw('width', 'auto', 200, 300)}
  }
  .col4 {
    ${vw('width', 'auto', 120, 250)}
    display: none;
    @media ${media.desktop} {
      display: block;
    }
  }

  .col5 {
    display: none;
    @media ${media.desktop} {
      display: block;
    }
    button {
      font-family: 'Gotham';
      font-style: normal;
      font-weight: 400;
      // font-size: 14px;
      ${vw('font-size', 10, 10, 14)}
      // line-height: 17px;
      ${vw('line-height', 14, 14, 17)}
      // color: ${(props) => props.theme.color.lightPink};
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
`;

const Header = styled.div`
  display: none;
  ${vw('padding-left', 0, 20)}

  p {
    text-transform: uppercase;
    ${vw('font-size', 16, 16, 17)}
    ${vw('line-height', 28, 28, 22)}
    ${vw('letter-spacing', 4, 4, 3)}
  }
  @media ${media.tablet} {
    display: flex;
  }
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media ${media.desktop} {
    flex-direction: row;
  }
  ${vw('margin-bottom', 30, 60, 70)}
  width: 100%;
  p {
    font-family: 'Gotham';
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: ${(props) => props.theme.color.navy};
  }

  .buttonWrapper {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    ${vw('gap', 12, 12, 24)}
    ${vw('margin-left', 20, 20, 20)}
    flex-wrap: wrap;

    button {
      cursor: pointer;
      display: flex;
      width: max-content;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: 1px solid ${({ theme }) => theme.color.lightPink};
      color: ${({ theme }) => theme.color.navy};
      transition: all 0.4s ease;
      font-family: 'Gotham';
      ${vw('height', 25, 37)}
      ${vw('padding-right', 12, 24)}
      ${vw('padding-left', 12, 24)}
      text-transform: uppercase;
    }
    button:hover {
      background: ${({ theme }) => theme.color.lightPink};
    }
    .active {
      background: ${({ theme }) => theme.color.lightPink};
    }
  }
`;

const Plans = styled.div`
  border-top: 2px solid ${({ theme }) => theme.color.navy};
  border-bottom: 2px solid ${({ theme }) => theme.color.navy};
  ${vw('margin-top', 0, 12, 16)}
  @media ${media.tablet} {
    border-top: 3px solid ${({ theme }) => theme.color.navy};
    border-bottom: 3px solid ${({ theme }) => theme.color.navy};
  }
`;

const Plan = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gold};
  ${(props) =>
    props.active ? vw('max-height', 999) : vw('max-height', 84, 95, 89)}
  transition: all 0.4s ease;
  overflow: hidden;
  .name {
    position: relative;
    p {
      font-family: ${({ theme }) => theme.font.header};
      text-transform: uppercase;
      ${vw('font-size', 18, 32)}
      ${vw('line-height', 30, 62)}
      ${vw('letter-spacing', 0.5, 1)}
      span {
        position: absolute;
        bottom: 0;
        ${vw('font-size', 10, 15)}
        ${vw('letter-spacing', 0.5, 1)}
      }
    }
  }

  .details {
    p {
      text-transform: uppercase;
      ${vw('font-size', 13, 16, 18)}
      ${vw('line-height', 21, 28)}
      ${vw('letter-spacing', 1, 4)}
    }
  }
  @media ${media.desktop} {
    .details p {
      font-size: ${vwDesktop(18)} !important;
    }
  }
`;

const Card = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  background-color: ${(props) =>
    props.active ? props.theme.color.lightPink : 'transparent'};
  color: ${(props) => props.theme.color.navy};
  transition: all 0.4s ease;
  ${vw('padding-left', 20, 20)}
  ${vw('padding-top', 20, 0)}
  ${vw('height', 83, 94, 88)}
  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.color.lightPink};
    }
  }

  .name {
    font-family: ${({ theme }) => theme.font.header};
    text-transform: uppercase;
    ${vw('font-size', 26, 54)}
    ${vw('line-height', 30, 62)}
    ${vw('letter-spacing', 0.5, 1)}
  }

  .details {
    p {
      text-transform: uppercase;
      ${vw('font-size', 13, 16)}
      ${vw('line-height', 21, 28)}
      ${vw('letter-spacing', 1, 4)}
    }
  }

  @media ${media.tablet} {
    align-items: center;
    .details {
      display: flex;
      align-items: center;
    }

    .mobile {
      display: none;
    }
  }

  svg {
    position: absolute;
    ${vw('top', 31, 44, 44)}
    ${vw('right', 20, 20, 20)}
    ${vw('width', 16, 22, 22)}
    ${vw('height', 8, 11, 11)}
    transform: ${(props) =>
      props.active
        ? 'translateY(-50%) rotate(180deg)'
        : 'translateY(-50%) rotate(0)'};
    transition: all 0.4s ease;
    path {
      stroke: ${(props) => (props.active ? 'white' : props.theme.color.navy)};
    }
  }
  @media ${media.desktop} {
    svg {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-top', 40, 40, 80)}
  ${vw('padding-bottom', 40, 40)}
  ${vw('padding-left', 40, 40, 250)}
  ${vw('padding-right', 40, 40, 250)}
  background-color: white;
  .png {
    ${vw('width', 288, 648, 670)}
    ${vw('margin-bottom', 40, 40, 40)}
  }

  .thumb {
    ${vw('height', 75, 75, 100)}
    ${vw('margin-bottom', 40, 0)}
    object-fit: contain;
    object-position: center left;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${media.tablet} {
    > div {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      // ${vw('padding-right', 0, 0, 72)}
      // ${vw('padding-left', 0, 0, 72)}
    }
  }
`;

const Disclaimer = styled.div`
  ${vw('margin-top', 20, 35, 16)};
  ${vw('font-size', 10, 10, 10)};
  ${vw('line-height', 12, 12, 12)};
`;
