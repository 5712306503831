import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'src/components/Button';
import Gallery from 'components/Gallery';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const ContentBlock = (props) => {
  const {
    title,
    text,
    intro,
    body,
    image,
    gallery,
    video,
    reverse,
    vertical,
    portrait,
  } = props;

  return (
    <Root className="navy" reverse={reverse} vertical={vertical}>
      {image ? (
        <ImageWrapper>
          <Image src={image} reverse={reverse} />
          <Gradient />
        </ImageWrapper>
      ) : null}
      {video && (
        <Video src={video} autoPlay muted playsInline loop reverse={reverse} />
      )}
      {gallery && (
        <GalleryWrapper reverse={reverse}>
          <Gallery data={gallery} portrait={portrait} />
          <Gradient />
        </GalleryWrapper>
      )}
      <Wrapper vertical={vertical} reverse={reverse}>
        <InnerWrapper>
          <Title sun={title === 'Under the Sun and Stars' && 1}>{title}</Title>

          {text && <Body className="body">{text}</Body>}

          {intro && <Body className="intro">{intro}</Body>}

          {body && <BodyList>{body}</BodyList>}
          {props.buttonLabel && (
            <StyledButton
              to={props.buttonTo}
              text={props.buttonLabel}
              className="button"
              lightPink
            />
          )}
        </InnerWrapper>
      </Wrapper>
    </Root>
  );
};

ContentBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  intro: PropTypes.string,
  image: PropTypes.string,
  video: PropTypes.string,
  reverse: PropTypes.bool,
  vertical: PropTypes.bool,
  gallery: PropTypes.array,
  buttonLabel: PropTypes.string,
  buttonTo: PropTypes.string,
  portrait: PropTypes.bool,
};

const Root = styled.div`
  ${vw('padding-top', 40, 60, 80)}
  ${vw('padding-bottom', 40, 60, 80)}
  ${vw('padding-left', 16, 0)}
  ${vw('padding-right', 16, 0)}
  // width: 100%;
  @media ${media.tablet} {
    margin: 0 ${vwTablet(60)};
  }

  @media ${media.desktop} {
    display: ${(props) => (props.vertical ? 'block' : 'flex')};
    align-items: center;
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    justify-content: space-between;
    margin-top: 0;
    margin-right: ${(props) => (props.reverse ? vwDesktop(80) : vwDesktop(80))};
    margin-left: ${(props) => (props.reverse ? vwDesktop(189) : vwDesktop(80))};
  }
`;

const GalleryWrapper = styled.div`
  ${vw('width', '100%', '100%', '50%')}
  @media ${media.desktop} {
    /* order: ${(props) => (props.reverse ? 1 : 2)}; */
  }
`;

const Wrapper = styled.div`
  margin-top: ${vwMobile(40)};
  @media ${media.tablet} {
    margin-top: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    width: ${(props) => (props.vertical ? '100%' : vwDesktop(527))};
    margin-top: ${(props) => (props.vertical ? vwDesktop(60) : 0)};
    margin-right: ${(props) =>
      props.vertical || props.reverse ? 0 : vwDesktop(54)};
    padding: ${(props) => (props.vertical ? `0 ${vwDesktop(160)}` : '0')};
    display: flex;
    flex-direction: column;
    align-items: center;
    order: ${(props) => (props.reverse ? 2 : 1)};
  }
`;

const InnerWrapper = styled.div`
  @media ${media.desktop} {
    width: ${(props) => (props.vertical ? '100%' : vwDesktop(527))};
  }
`;

const Title = styled.h2`
  margin-bottom: ${vwTablet(40)};
  ${vw('font-size', 24, 40)}
  ${vw('line-height', 32, 48)}
  font-weight: 300;
  color: ${(props) => props.theme.color.navy};

  @media ${media.tablet} {
    max-width: ${vwTablet(415)};
    margin-bottom: ${vwTablet(40)};
  }

  @media ${media.desktop} {
    max-width: ${(props) => (props.sun ? vwDesktop(400) : vwDesktop(500))};
    margin-bottom: ${(props) => (props.vertical ? 0 : vwDesktop(40))};
  }
`;

const Body = styled.p`
  @media ${media.desktop} {
    width: ${vwDesktop(527)};
  }
`;

const BodyList = styled.div`
  position: relative;
  list-style: none;
  text-align: left;

  @media ${media.desktop} {
    width: ${vwDesktop(527)};
    margin-left: 0;
    margin-top: 0;
  }

  p {
    color: #444444;
    ${vw('padding-top', 10, 18)}
    ${vw('padding-bottom', 10, 18)}
    border-bottom: 1px solid ${theme.color.blue};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: none;
      padding-bottom: 0;
    }

    @media ${media.desktop} {
      width: ${vwDesktop(527)};
    }
  }
`;

const StyledButton = styled(Button)`
  ${vw('margin-top', 20, 40)};
`;

const ImageWrapper = styled.div`
  ${vw('width', '100%', '100%', '50%')}
  position: relative;
  z-index: 0;
`;

// const Frame = styled.div`
//   display: none;
//   border: 1px solid ${(props) => props.theme.color.gold};
//   position: absolute;
//   bottom: ${vw(-40)};
//   ${(props) => (props.reverse ? vw('right', -24) : vw('left', -24))}
//   ${vw('top', -24)}
//   ${vw('width', 288, 648, 600)}
//   ${vw('height', 192, 432, 400)}
//   z-index: 1;
//   @media ${media.desktop} {
//     display: block;
//   }
// `;

const Image = styled.img`
  display: block;
  width: 100%;
  ${vw('height', 192, 432, 480)}
  /* display: block; */
  position: relative;
  z-index: 2;
  object-fit: cover;
`;

const Video = styled.video`
  width: 100%;
  ${vw('height', 220, 498, 480)}
  object-fit: cover;
  display: block;

  @media ${media.desktop} {
    width: ${vwDesktop(624)};
  }
`;

const Gradient = styled.div`
  width: 100%;
  // height: 20px;
  ${vw('height', 10, 10, 20)}
  background: linear-gradient(90deg, #8B6153 0%, #C08D7A 7.78%, #DAA38D 16.62%, #C9937F 28.76%, #B88473 37.04%, #DAA38D 48.36%, #C08B79 59.13%, #C28D7B 68.79%, #DAA38D 79.83%, #B58371 90.32%, #916556 100%);
`;

export default ContentBlock;
