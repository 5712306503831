import React from 'react';
import styled, { css } from 'styled-components';
import useIntl from 'components/useIntl';

import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Features = () => {
  const { t } = useIntl();

  return (
    <Root className="navy">
      <Container>
        <Col first>
          {t.interiors.features.column1.map((feature, i) => (
            <p key={i}>
              <span>✓</span>
              {feature}
            </p>
          ))}
        </Col>

        <Col second>
          {t.interiors.features.column2.map((feature, i) => (
            <p key={i}>
              <span>✓</span>
              {feature}
            </p>
          ))}
        </Col>
      </Container>

      <StyledButton
        text={t.interiors.features.download}
        href="/pdfs/Sydney Rental - Feature Sheet.pdf"
        lightPink
      />
    </Root>
  );
};

export default Features;

const First = css`
  p {
    border-bottom: 1px solid ${({ theme }) => theme.color.gold};
    span {
      ${vw('margin-right', 10, 10, 15)}
    }
  }
  @media ${media.desktop} {
    p {
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
`;

const Second = css`
  p {
    border-bottom: 1px solid ${({ theme }) => theme.color.gold};
    span {
      ${vw('margin-right', 10, 10, 15)}
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-right', 16, 60, 0)}
  ${vw('padding-left', 16, 60, 0)}
  h2 {
    align-self: flex-start;
    ${vw('margin-bottom', 20, 40)}
  }
  ${vw('padding-top', 60, 80, 120)}
  ${vw('padding-bottom', 80, 120, 160)}

  @media ${media.desktop} {
    h2 {
      align-self: center;
    }
  }
`;

const Container = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: center;
  }
`;

const Col = styled.div`
  margin: 0 auto;
  ${vw('width', 288, 648, 408)}

  &:last-of-type {
    ${vw('margin-left', 'auto', 'auto', 85)}
  }

  p {
    ${vw('padding-bottom', 10)}
    ${vw('margin-bottom', 10)}
    ${vw('font-size', 10, 13, 14)}
  }

  ${(props) => props.first && First}
  ${(props) => props.second && Second}

  @media ${media.desktop} {
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  ${vw('margin-top', 30, 60, 90)}
`;
