/* eslint-disable no-control-regex */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ReCaptcha } from 'react-recaptcha-v3';

import useIntl from 'components/useIntl';
import Input from './Input';
import Select from './Select';
import Radio from './Radio';
import Checkbox from './Checkbox';
import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import { recaptcha } from 'src/config/app.conf';

const Form = () => {
  const { t, getLocaleURL } = useIntl();

  useEffect(() => {
    document.getElementById('are_you_simulated').style.display = 'none';
  }, []);

  let submitting = false;

  function submitRegistrationForm(element) {
    element.preventDefault();
    var form = document.querySelector(
      'body#spark-registration-form form, form#spark-registration-form'
    );
    var missing = '';
    var required = { contact_email: 'Email' };
    var customRequired = document.querySelectorAll(
      'input:required, textarea:required, select:required'
    );

    // Adds custom required inputs to the 'required' object
    for (var i = 0; i < customRequired.length; i++) {
      required[customRequired[i].id] = customRequired[
        i
      ].parentNode.firstElementChild.innerHTML.replace('*', '');
    }

    // Iterates through required fields and adds any that have
    // not been populated to 'missing' list
    for (let key in required) {
      var elements = Array.from(
        document.querySelectorAll("[id^='" + key + "']")
      );

      if (elements.length > 0) {
        let missing_field = true;

        elements.forEach(function (el) {
          if (
            el.length < 1 ||
            (el &&
              ((el.type === 'checkbox' && el.checked) ||
                (el.type === 'radio' && el.checked) ||
                (el.type !== 'radio' && el.type !== 'checkbox' && el.value) ||
                (document.getElementById(key) &&
                  document.getElementById(key).value)))
          ) {
            missing_field = false;
          }
        });

        if (missing_field) {
          // console.log(required);
          missing += '- ' + required[key] + '\r\n';
        }
      }
    }

    // Tests email input value against RFC 5322 Official Standard Email Regex
    var email = document.getElementById('contact_email').value;
    if (
      !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email
      )
    ) {
      missing += '- Email is invalid\r\n';
    }

    if (missing !== '') {
      alert('The following fields are incomplete:\r\n' + missing);
      return false;
    }

    // Prevents duplicate submissions
    if (submitting) {
      return false;
    }
    submitting = true;

    // If you are hosting this form on your own site and have reCAPTCHA enabled,
    // ensure that this is populated with your own site key, as well as include
    // the following script before this code:
    //   <script src="https://www.google.com/recaptcha/api.js?render=YOUR_SITE_KEY">
    // For more information, see: https://developers.google.com/recaptcha/docs/v3
    var siteKey = '6LeqgpMfAAAAADk_Gy0b1Cok22ALl-9Qw8bjbktB';
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(siteKey, { action: 'registration' })
        .then(function (token) {
          const tokenInput = document.querySelector('.g-recaptcha-response');
          if (tokenInput) {
            tokenInput.value = token;
            form.appendChild(tokenInput);
          }
          form.submit();
        });
    });

    return false;
  }

  return (
    <Root
      id="spark-registration-form"
      action="https://spark.re/led-mac/sydney-rentals/register/sydney-rental-website-form"
      accept-charset="UTF-8"
      method="post"
      onSubmit={submitRegistrationForm}
    >
      <input name="utf8" type="hidden" value="&#x2713;" />
      <input
        type="hidden"
        name="authenticity_token"
        value="ckLTTS/Shu1q2GjfxFuSONkvFY1NuOZ6258cT3bWIRr9lbaGGSi2NiueHny1VP3TMp/cn+Qy9kfH7eFQaGpk0A=="
      />
      <input type="hidden" name="source" id="source" value="Website" />
      <input
        type="hidden"
        name="redirect_success"
        id="redirect_success"
        value={`${window.location.origin}${getLocaleURL()}/thank-you`}
      />
      <input
        type="hidden"
        name="redirect_error"
        id="redirect_error"
        value={`${window.location.origin}${getLocaleURL()}/thank-you2`}
      />
      <input
        type="text"
        name="are_you_simulated"
        id="are_you_simulated"
        placeholder="Leave this field blank"
      />
      <Container>
        <Input
          required
          label="First Name"
          type="text"
          name="contact[first_name]"
          id="contact_first_name"
        />
        <Input
          required
          label="Last Name"
          type="text"
          name="contact[last_name]"
          id="contact_last_name"
        />
        <Input
          required
          label="Email"
          type="email"
          name="contact[email]"
          id="contact_email"
        />
        <Input
          required
          label="Phone"
          type="tel"
          name="contact[phone]"
          id="contact_phone"
        />

        <Select
          name="answers[10797][answers]"
          id="answers_10797"
          required
          label={t.form.howHear}
          options={t.form.howHearOptions}
        />

        <Radio
          name="answers[10852][answers][]"
          id="answers[10852][answers][]"
          label={t.form.howMany}
          options={t.form.howManyOptions}
          required
        />
      </Container>
      <Checkbox
        name="answers[9157][answers][]"
        id="answers_9157_yes--i-have-expressed-consent"
        label={t.form.disclaimer}
      />
      <Button
        text={t.form.submit}
        handleClick={(f) => f}
        button
        type="submit"
        lightPink
        className="submit"
      />
      <ReCaptcha sitekey={recaptcha} action="registration" />
    </Root>
  );
};

const Root = styled.form`
.submit {
    width: 100%;
    @media ${media.desktop} {
      width: initial;
    }
    button {
      width: 100%;
    }
  }
}`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('width', '100%', '100%', 780)};
`;

// const Line = styled.span`
//   @media ${media.desktop} {
//     display: block;
//     width: 1px;
//     height: ${vwDesktop(426)};
//     background-color: #dddddd;
//     margin: 0 ${vwDesktop(39)} 0 ${vwDesktop(40)};
//   }
// `;

export default Form;
