import React from 'react';
import styled from 'styled-components';

import useIntl from 'components/useIntl';
import Slick from '../elements/Slick';

import theme from 'src/styles/theme';

import vw from 'src/styles/utils';

const Hero = () => {
  const { t } = useIntl();

  return (
    <Root>
      <ImageWrapper>
        <Image
          src={require('src/assets/images/_global/logos/ledmac-logo.svg')}
          width="400"
        />
        <h1>{t.developer.hero.title}</h1>
      </ImageWrapper>

      <TextWrapper>
        <p className="body navy">{t.developer.hero.body}</p>
      </TextWrapper>

      <Slick images={t.developer.projects} />
    </Root>
  );
};

export default Hero;

const Root = styled.div`
  ${vw('padding-top', 132, 200, 240)}
  h1 {
    font-family: ${theme.font.family}
    text-align: center;
    ${vw('margin-top', 35, 67, 90)}
    ${vw('margin-bottom', 32, 20)}
    font-weight: 400;
    ${vw('font-size', 14, 18, 18)}
    text-transform: uppercase;
    color: #4b4e52;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-left', 16, 24, 48)}
  ${vw('padding-right', 16, 24, 48)}
`;

const Image = styled.img`
  ${vw('width', 180, 321)}
`;

const TextWrapper = styled.div`
  ${vw('width', 288, 600, 1026)}
  margin: auto;
  text-align: center;
  p {
    margin: 0;
  }
`;
