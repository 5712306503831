import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';
import { Link } from 'react-router-dom';

import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Project = () => {
  const { t, getLocaleURL } = useIntl();

  return (
    <Root>
      <Logo to={`${getLocaleURL()}`}>
        <img
          src={require('src/assets/images/_global/logos/sydney_logo.png')}
          alt="Sydney logo"
        />
      </Logo>

      <Container>
        <p className="title margin uppercase">{t['footer'].column1.title}</p>
        <Button
          href="https://sydneybyledmac.com/"
          text={t.visitSydneyHomes}
          lightPink
        />
      </Container>

      <Container>
        <p className="title uppercase">{t['footer'].column2.title}</p>

        <p>
          <a href="tel: +2363085989">236.308.5989</a>
          <a href="mailto: admin@rentatsydney.com">admin@rentatsydney.com</a>
          <br />
          <a
            className="footer-link"
            href="https://goo.gl/maps/ARop69YK8zM4EmLm9"
            target="_blank"
            rel="noopener noreferrer"
          >
            545 Sydney Avenue, <br />
            Coquitlam, BC V3J 3X5
          </a>
        </p>
      </Container>

      <Container>
        <p className="title uppercase">{t['footer'].column3.title}</p>

        <Map
          href="https://goo.gl/maps/ARop69YK8zM4EmLm9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require('src/assets/images/_global/map@2x.png')}
            alt="map"
          />
        </Map>
      </Container>
    </Root>
  );
};

export default Project;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.navy};
  ${vw('padding-top', 40, 60, 50)}
  ${vw('padding-right', 0, 0, 100)}
  ${vw('padding-bottom', 40, 60, 50)}
  ${vw('padding-left', 0, 0, 100)}
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
const Logo = styled(Link)`
  img {
    ${vw('width', 100, 143)}
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${vw('margin-bottom', 20, 40)}
  ${vw('margin-top', 32, 32, 0)}
  p {
    display: block;
    color: white;

    a {
      display: block;
      color: white;
    }
    a:hover {
      color: ${({ theme }) => theme.color.lightPink};
      transition: all 0.5s ease;
    }
  }
  .title {
    font-weight: 700;
    font-family: 'Kessel-105';
    ${vw('font-size', 12, 14, 14)}
    ${vw('line-height', 16, 21, 21)}
    ${vw('letter-spacing', 2, 2, 2)}
    ${vw('margin-bottom', 8, 10, 12)}
    &.margin {
      ${vw('margin-bottom', 12, 12, 14)}
    }
  }
  .body {
    ${vw('margin-bottom', 20, 40)}
  }
  .footer-link:hover {
    color: ${({ theme }) => theme.color.lightPink};
    transition: all 0.5s ease;
  }
`;

const Map = styled.a`
  display: block;
  img {
    ${vw('width', 160, 200)}
  }
`;
