import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Hero from 'components/Layout/Hero';
import Slick from './elements/Slick';

const Gallery = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero
        title={t.gallery.hero.title}
        src={require('src/assets/images/gallery/hero.png')}
        gradient
      />
      <Slick
        title={t.gallery.interiors.title}
        images={t.gallery.interiors.images}
      />
      <Slick
        title={t.gallery.neighbourhood.title}
        images={t.gallery.neighbourhood.images}
      />
    </Root>
  );
};

export default Gallery;

const Root = styled.div``;
