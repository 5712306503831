import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Gallery = (props) => {
  const { data } = props;

  const [activeImage, setActiveImage] = useState(0);

  const swiping = useSwipeable({
    onSwipedLeft: (e) => handleSwipe(e),
    onSwipedRight: (e) => handleSwipe(e),
  });

  const handleNext = useCallback(() => {
    if (data.length - 1 === activeImage) {
      setActiveImage(0);
    } else {
      setActiveImage(activeImage + 1);
    }
  }, [activeImage, data.length]);

  useEffect(() => {
    const interval = setInterval(() => handleNext(), 2500);
    return () => clearInterval(interval);
  }, [handleNext]);

  const handlePrev = () => {
    if (activeImage === 0) {
      setActiveImage(data.length - 1);
    } else {
      setActiveImage(activeImage - 1);
    }
  };

  const handleSwipe = (e) => {
    if (e.dir.toLowerCase() === 'right') {
      handlePrev();
    } else {
      handleNext();
    }
  };

  return (
    <Root {...swiping}>
      {data.map((image, index) => (
        <Image
          src={image.src}
          alt={image.alt}
          key={index}
          style={{
            opacity: activeImage === index ? 1 : 0,
            pointerEvents: activeImage === index ? 'all' : 'none',
          }}
        />
      ))}
      <Arrow
        src={require('src/assets/images/_global/icons/carousel-arrow-left.svg')}
        alt="Previous"
        onClick={handlePrev}
      />
      <Arrow
        src={require('src/assets/images/_global/icons/carousel-arrow-right.svg')}
        alt="Next"
        onClick={handleNext}
        right
      />
      <Dots>
        {data.map((image, i) => (
          <Dot
            key={i}
            active={i === activeImage}
            onClick={() => setActiveImage(i)}
          />
        ))}
      </Dots>
    </Root>
  );
};

Gallery.propTypes = {
  data: PropTypes.array,
};

const Root = styled.div`
  width: 100%;
  height: ${vwMobile(196)};
  position: relative;
  @media ${media.tablet} {
    height: ${vwTablet(466)};
  }
  @media ${media.desktop} {
    // width: ${vwDesktop(720)};
    height: ${vwDesktop(480)};
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: ${({ theme }) => theme.transition};
`;

const Arrow = styled.img`
  display: block;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  ${vw('width', 24, 32, 32)}
  ${(props) => !props.right && vw('left', 12, 20)}
  ${(props) => props.right && vw('right', 12, 20)}
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  ${vw('bottom', 12, 20)}
`;

const Dot = styled.span`
  display: block;
  position: relative;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.copper};
    transition: ${({ theme }) => theme.transition};
    opacity: ${(props) => (props.active ? 1 : 0)};
    ${vw('width', 4, 6)}
    ${vw('height', 4, 6)}
  }
  &:last-of-type {
    margin-right: 0;
  }
  ${vw('margin-right', 12, 20)}
  ${vw('width', 6, 8)}
  ${vw('height', 6, 8)}
`;

export default Gallery;
