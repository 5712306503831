import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'src/styles/theme';

import vw from 'src/styles/utils';

const Title = (props) => {
  const { text, small, gradient } = props;

  return (
    <Root small={small} gradient={gradient}>
      <h1 className="pageTitle">{text}</h1>
    </Root>
  );
};

export default Title;

Title.propTypes = {
  text: PropTypes.string,
  small: PropTypes.bool,
  gradient: PropTypes.bool,
};

const Root = styled.div`
  ${vw('padding-top', 132, 200, 240)}
  ${vw('padding-right', 16, 60, 48)}
  ${vw('padding-left', 16, 60, 48)}
  h1 {
    font-family: ${theme.font.header};
    text-align: center;
    margin: 0 auto;


    ${(props) =>
    props.gradient &&
    css`
      background: -webkit-linear-gradient(90deg, #8B6153 0%, #C08D7A 7.78%, #DAA38D 16.62%, #C9937F 28.76%, #B88473 37.04%, #DAA38D 48.36%, #C08B79 59.13%, #C28D7B 68.79%, #DAA38D 79.83%, #B58371 90.32%, #916556 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `
  }

  }
`;
