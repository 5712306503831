export default {
  color: {
    gold: '#B7A165',
    grey: '#DDDDDD',
    lightGold: '#E4E2DB',

    navy: '#333F48',
    lightPink: '#EABEB0',
    copper: '#7C4D3A',
    white: '#FFFFFF',
    darkGrey: '#444444',
  },
  font: {
    header: 'Gotham',
    family: 'Kessel-105',
  },
  transition: '0.4s ease'
};
