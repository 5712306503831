import React from 'react';
import styled from 'styled-components';

import useIntl from 'components/useIntl';
import Button from 'components/Button';
import Hero from './sections/Hero';
import ContentBlock from 'components/Layout/ContentBlock';
import LineCallout from 'components/Layout/LineCallout';

import vw from 'src/styles/utils';

import theme from 'src/styles/theme';

const Home = () => {
  const { t, getLocaleURL } = useIntl();

  return (
    <Root>
      <Hero />
      <Content>
        <LineCallout
          text={t.home.intro}
          lineColor={theme.color.copper}
          small={false}
        />

        <div className="buttonWrapper">
          <StyledButton
            to={`${getLocaleURL()}/floorplans`}
            text="find your home"
            className="button"
            lightPink
          />
        </div>
        <ContentBlock
          title={t.home.content.block1.title}
          text={t.home.content.block1.text}
          buttonTo={`${getLocaleURL()}/neighbourhood`}
          buttonLabel={t.home.content.block1.buttonLabel}
          gallery={[
            {
              src: require('src/assets/home/homepage_neighbourhood_01_skytrain.jpg'),
              alt: 'Skytrain',
            },
            {
              src: require('src/assets/home/homepage_neighbourhood_02_golf.jpg'),
              alt: 'Golf',
            },
            {
              src: require('src/assets/home/homepage_neighbourhood_03_twinsails.jpg'),
              alt: 'Twinsails',
            },
          ]}
        />
        <ContentBlock
          title={t.home.content.block2.title}
          text={t.home.content.block2.text}
          buttonTo={`${getLocaleURL()}/interiors`}
          buttonLabel={t.home.content.block2.buttonLabel}
          reverse
          gallery={[
            {
              src: require('src/assets/home/slider_interiors_01_couple.jpg'),
              alt: 'Smiling Couple',
            },
            {
              src: require('src/assets/interiors/slider_kitchen.jpg'),
              alt: 'Kitchen',
            },
            {
              src: require('src/assets/home/slider_interiors_03_ensuite.jpg'),
              alt: 'Ensuite',
            },
          ]}
        />
        <ContentBlock
          title={t.home.content.block3.title}
          text={t.home.content.block3.text}
          buttonTo={`${getLocaleURL()}/amenities`}
          buttonLabel={t.home.content.block3.buttonLabel}
          gallery={[
            {
              src: require('src/assets/home/homepage_amenities_01_gym.jpg'),
              alt: 'Fitness',
            },
            {
              src: require('src/assets/home/homepage_amenities_02_lobby.jpg'),
              alt: 'Lobby',
            },
          ]}
        />
      </Content>
    </Root>
  );
};

export default Home;

const Root = styled.div``;

// const Wrapper = styled.div`
//   background-color: ${({ theme }) => theme.color.navy};
//   ${vw('padding-top', 133, 216, 223)}

//   h1 {
//     color: white;
//     margin: 0 auto;
//     text-align: center;
//   }

//   .tablet {
//     display: none;
//   }

//   @media ${media.tablet} {
//     .mobile {
//       display: none;
//     }
//     .tablet {
//       display: block;
//     }
//   }
// `;

const Content = styled.div`
  ${vw('padding-top', 20, 20, 40)}
  ${vw('padding-bottom', 20, 20, 40)}
  display: flex;
  flex-direction: column;
  // align-items: center;
  .buttonWrapper {
    // width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const StyledButton = styled(Button)`
  ${vw('margin-top', 20, 40)};
  ${vw('margin-bottom', 20, 40)};
`;
