module.exports = {
  defaultLocale: 'en',
  locales: ['en'],
  prerender: true,
  analytics: {
    gtag: '',
    gtmId: 'GTM-TNMZ6LN',
    optimizeId: '',
    hotjarId: '',
  },
  seo: {
    title: 'Sydney Rentals',
  },
  recaptcha: '6LeqgpMfAAAAADk_Gy0b1Cok22ALl-9Qw8bjbktB',
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1760,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
};
