import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Radio = (props) => {
  const { required, label, name, id, options } = props;
  return (
    <Root>
      <label htmlFor={id} className="form label">
        <p>{label}</p>
      </label>
      <Options>
        {options.map((item, index) => (
          <div key={index}>
            <input
              required={required}
              name={name}
              id={name + item.value}
              type="radio"
              value={item.value}
            />
            <div className="circle">
              <span />
            </div>
            <label for={name + item.value} className="form input">
              <p>{item.label}</p>
            </label>
          </div>
        ))}
      </Options>
    </Root>
  );
};

Radio.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

const Root = styled.div`
  width: 100%;
  ${vw('margin-bottom', 20, 24)}
  .label {
    display: block;
    ${vw('margin-bottom', 4)}
  }
`;

const Options = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${vw('gap', 10, 16, 32)}
  @media ${media.desktop} {
    height: ${vwDesktop(40)};
    align-items: center;
    flex-direction: row;
  }
  > div {
    display: flex;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    ${vw('padding-left', 32)}
    ${vw('height', 24)}
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    color: ${({ theme }) => theme.color.navy};
    cursor: pointer;
    ${vw('width', 24)}
    ${vw('height', 24)}
    &:checked ~ .circle span {
      display: block;
    }
  }
  .circle {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.color.navy};
    pointer-events: none;
    ${vw('width', 24)}
    ${vw('height', 24)}
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: none !important;
      background-color: ${({ theme }) => theme.color.gold};
      display: none;
      &:focus,
      &:active {
        outline: none !important;
        border: none !important;
      }
      ${vw('width', 12)}
      ${vw('height', 12)}
    }
  }
`;

export default Radio;
