import React, { useState } from 'react';
import styled from 'styled-components';
import Nav from './elements/Nav';
import Menu from './elements/Menu';
import { useLocation } from 'react-router-dom';
import { useScrollDown, useHasScrolledPast } from 'bam-storybook';

import vw from 'src/styles/utils';
const Header = () => {

  const [isOpen, setIsOpen] = useState(false);

  const hasScrolledPast = useHasScrolledPast(100);
  const isScrollingDown = useScrollDown(
    (document.getElementById('hero') &&
      document.getElementById('hero').clientHeight) ||
    1000
  );
  const location = useLocation();

  const isHome =
    location.pathname.replaceAll('/', '') === '' ||
    location.pathname.replaceAll('/', '') === 'ko' ||
    location.pathname.replaceAll('/', '') === 'tc';


  return (
    <Root
      hasScrolledPast={hasScrolledPast}
      isScrollingDown={isScrollingDown}
      isHome={isHome}
    >
      <Nav
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hasScrolledPast={hasScrolledPast}
        isScrollingDown={isScrollingDown}
        isHome={isHome}
      />
      <Menu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Root>
  )
}

export default Header;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.navy};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  transition: ${({ theme }) => theme.transition};
  transform: ${(props) =>
    props.isScrollingDown && props.isHome
      ? 'translateY(-100%)'
      : 'translateY(0)'};
  background-color: ${(props) =>
    props.hasScrolledPast || !props.isHome
      ? props.theme.color.navy
      : 'transparent'};
  ${(props) =>
    props.hasScrolledPast || !props.isHome
      ? vw('padding-top', 28, 20, 20)
      : vw('padding-top', 28, 20, 40)}
  ${(props) =>
    props.hasScrolledPast || !props.isHome
      ? vw('padding-bottom', 28, 20, 20)
      : vw('padding-bottom', 28, 20, 40)}
  ${vw('padding-left', 16, 30, 62)}
  ${vw('padding-right', 12, 30, 62)}
`;
