import useIntl from 'components/useIntl';
import React from 'react';
import styled from 'styled-components';

import ContentBlock from 'components/Layout/ContentBlock';
import Hero from 'components/Layout/Hero';
import Features from './sections/Features';

const Interiors = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero title={t.interiors.hero.title} gradient />

      <ContentBlock
        title={t.interiors.content.block1.title}
        text={t.interiors.content.block1.text}
        image={require('src/assets/interiors/slider_kitchen.jpg')}
        reverse
      />
      {/* <ContentBlock
        title={t.interiors.content.block2.title}
        text={t.interiors.content.block2.text}
        gallery={[
          {
            src: require('src/assets/interiors/slider_ensuite.jpg'),
            alt: 'Interior',
          },
          {
            src: require('src/assets/interiors/slider_kitchen.jpg'),
            alt: 'Kitchen',
          },
        ]}
      /> */}
      <ContentBlock
        title={t.interiors.content.block2.title}
        text={t.interiors.content.block2.text}
        image={require('src/assets/interiors/slider_ensuite.jpg')}
      />
      <Features />
    </Root>
  );
};

export default Interiors;

const Root = styled.div``;

// const ShowOnMobile = styled.div`
//   display: block;
//   color: ${({ theme }) => theme.color.navy};
//   @media ${media.tablet} {
//     display: none;
//   }
// `;

// const Body = styled.div`
//   margin: 0 auto;
//   h2 {
//     ${vw('margin-bottom', 20, 40)}
//   }
//   ${vw('width', 288, 648, 470)}
//   ${vw('margin-top', 40, 40, 0)}
//   ${vw('margin-left', 'auto', 'auto', 85)}
// `;
