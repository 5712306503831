import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useIntl from 'components/useIntl';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const List = (props) => {
  const { activeCategory, handleCategory, activePoint, handlePoint } = props;

  const { t } = useIntl();

  return (
    <Root>
      <Strapline>{t.neighbourhood.select}</Strapline>
      {t.neighbourhood.map.map((category, i) => (
        <Category active={activeCategory === i} key={i}>
          <h4 onClick={() => handleCategory(i)}>
            {category.title}
            {/* <img
              src={require('src/assets/images/neighbourhood/arrow-down.svg')}
              alt="Select"
              className="arrow"
            /> */}
            <svg
              className="arrow"
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 1.65625L9 9.65625L0.999999 1.65625"
                stroke="white"
                stroke-width="2"
              />
            </svg>

            <img
              src={require('src/assets/images/neighbourhood/plus_icon.svg')}
              alt="Select"
              className="plus"
            />
          </h4>
          <ul>
            {category.list.map((poi, j) => (
              <Point
                active={activePoint === j}
                onClick={() => handlePoint(j)}
                key={j}
              >
                <span>{j + 1}</span> <p className="navy">{poi.name}</p>
              </Point>
            ))}
          </ul>
        </Category>
      ))}
    </Root>
  );
};

export default List;

List.propTypes = {
  strapline: PropTypes.string,
  activeCategory: PropTypes.number,
  handleCategory: PropTypes.func,
  activePoint: PropTypes.number,
  handlePoint: PropTypes.func,
};

const Root = styled.div`
  ${vw('padding-right', 16, 60, 0)}
  ${vw('padding-left', 16, 60, 0)}
`;

const Strapline = styled.div`
  font-family: ${({ theme }) => theme.font.family};
  font-weight: 400;
  color: ${({ theme }) => theme.color.navy};
  ${vw('font-size', 10, 12)}
  ${vw('line-height', 14, 16)}
  ${vw('margin-left', 10, 20)}
  ${vw('margin-bottom', 10, 10)}
  @media ${media.desktop} {
    display: none;
  }
`;

const Category = styled.div`
  ${vw('width', 288, 648, 390)}
  ${vw('margin-left', 0, 0, 34)}
  &:not(:first-of-type) {
    h4 {
      border-top: 1px solid
        ${(props) => props.theme.color.lightPink};
    }
  }
  h4 {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    background: transparent;
    font-family: ${({ theme }) => theme.font.family};
    text-transform: uppercase;
    font-weight: 500;
    color: ${(props) => props.theme.color.navy}
    ${vw('font-size', 18, 21)}
    ${vw('line-height', 20, 20)}
    ${vw('letter-spacing', 1, 1)}
    ${vw('height', 40, 52)}
    ${vw('padding-left', 12, 20)}
  }
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${vw('width', 16, 22)}
    ${vw('right', 14, 20)}
    transform: ${(props) =>
    props.active
      ? 'translateY(-50%) rotate(180deg)'
      : 'translateY(-50%) rotate(0)'};
    transition: all 0.4s ease;
    path {
      stroke: ${(props) => props.theme.color.lightPink};
    }
  }
  .plus {
    display: none;
  }
  ul {
    list-style: none;
    overflow-y: scroll;
    ${vw('height', 209, 266, 247)}
    ${(props) =>
    props.active
      ? vw('max-height', 214, 271, 247)
      : vw('max-height', 0, 0, 0)}
    ${(props) =>
    props.active ? vw('margin-top', 7, 11, 30) : vw('margin-top', 0)}
    ${(props) =>
    props.active ? vw('margin-bottom', 7, 11, 30) : vw('margin-bottom', 0)}

    ::-webkit-scrollbar {
      border-radius: 2px;
      ${vw('width', 2, 4)}
    }

    ::-webkit-scrollbar-track {
      background: rgba(0, 38, 58, 0.2);
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.lightPink};
      border-radius: 2px;
    }
  }
  @media ${media.desktop} {
    .arrow {
      display: none;
    }
    .plus {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: ${(props) =>
    props.active ? 'translateY(-50%) rotate(45deg)' : 'translateY(-50%)'};
      transition: all 0.4s ease;
      ${vw('width', 12)}
    }
    &:not(:first-of-type) {
      h4 {
        border-top: 2px solid ${({ theme }) => theme.color.lightPink};
      }
    }
    &:last-of-type {
      h4 {
        border-bottom: 2px solid ${({ theme }) => theme.color.lightPink};
      }
    }
    h4 {
      background-color: transparent;
      color: ${({ theme }) => theme.color.navy};
      border-top: 2px solid ${({ theme }) => theme.color.lightPink};
      border-bottom: 2px solid
        ${(props) => (props.active ? props.theme.color.lightPink : 'transparent')};
    }
  }
`;

const Point = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${vw('padding-left', 12, 0, 0)}

  &:not(:last-of-type) {
    ${vw('margin-bottom', 9, 14, 9)}
  }
  span {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.font.family};
    font-weight: 600;
    line-height: normal;
    background-color: ${({ theme }) => theme.color.navy};
    color: white;
    border-radius: 50%;
    ${vw('font-size', 10, 12, 14)}
    ${vw('flex-basis', 15, 20, 35)}
    ${vw('height', 15, 20, 35)}
    ${vw('margin-right', 23, 18, 26.5)}
  }
  @media ${media.desktop} {
    span {
      color: ${(props) => props.theme.color.navy};
      background-color: ${(props) =>
    props.active ? props.theme.color.lightPink : 'transparent'};
    }
  }
`;
