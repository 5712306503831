import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Hero from 'components/Layout/Hero';
import MapSection from './sections/MapSection';
import ContentBlock from 'components/Layout/ContentBlock';

import vw from 'src/styles/utils';

const Neighbourhood = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero
        title={t.neighbourhood.hero.title}
        fullStrapline={t.neighbourhood.hero.fullStrapline}
        gradient
      />
      <MapSection />
      <Desc>
        <p>{t.neighbourhood.desc}</p>
      </Desc>
      <ContentBlock
        title={t.neighbourhood.content1.title}
        text={t.neighbourhood.content1.text}
        image={require('src/assets/images/neighbourhood/mall.jpg')}
      />
      <ContentBlock
        title={t.neighbourhood.content2.title}
        text={t.neighbourhood.content2.text}
        image={require('src/assets/images/neighbourhood/skytrain.jpg')}
        reverse
      />
      <Space />
    </Root>
  );
};

export default Neighbourhood;

const Root = styled.div``;
const Space = styled.div`
  ${vw('height', 90, 90)}
`;
const Desc = styled.div`
  ${vw('padding-left', 16, 0)}
  ${vw('padding-right', 16, 0)}
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('margin-top', 60, 120, 180)}
  ${vw('margin-top', 30, 60, 120)}
  p {
    width: 100px;
    text-align: center;
    color: #333f48;
    ${vw('margin-top', 24, 40, 0)}
    ${vw('margin-bottom', 24, 40, 0)}
    ${vw('margin-left', 0, 0, 40)}
    ${vw('margin-right', 0, 0, 40)}
    ${vw('width', '100%', 440, 526)}
  }
`;
